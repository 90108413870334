import React from 'react';
import { ReactComponent as Logo } from '../../../static/logo-svg-code.svg';

const heroData = {
	title: 'keeps money moving',
	titleLogo: <Logo />,
	topDescription:
		'A buyer in Wyoming. A seller in Moldova. Their worlds touch on Jane\'s platform, simply and productively. Solve problems with people working from anywhere, in a workspace that puts the entire world onto one, single timezone.',
	bottomDescription:
		"Jane's boards, lists, and cards rises the bar of online work far beyond a simple chat. Do better work that give results needed to disrupt competitors far bigger than you. Launched in the coronavirus pandemic, facing the deepest global recession since WW2; HostJane shares a purpose with startups to find new income in impossible conditions. HostJane is made for survivors to #keepmoneymoving.",
	heroGif: 'https://www.hostjane.com/assets/a-connected-world.gif',
};

export default heroData;
